import { data } from 'autoprefixer'

export default (api) => ({
  uploadFile: (data, onUploadProgress) =>
    api.post('profile/upload/', data, {
      header: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    }),
  getProducts: () => api.get('products/'),
  addProduct: (data) => api.post('product/edit/', data),
  getProduct: (id) => api.get(`product/${id}/`),
  deleteProduct: (id) => api.delete(`product/${id}/delete/`),
  getCategories: () => api.get('categories/'),
  addCategory: (data) => api.post('category/edit/', data),
  getAttributes: () => api.get('attributes/'),
  addAttribute: (data) => api.post('attribute/add/', data),
  addOption: (data) => api.post('option/add/', data),
  uploadImages: (data) => api.post('upload/', data),
  getToken: (data) => api.post('https://sandbox.api.otto.market/v1/token', data),
})
