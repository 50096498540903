<script setup lang="ts">
import { useProductStore } from '@/stores/products'
import { onMounted} from 'vue'
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute();
const { getCategories, getAttributes } = useProductStore()
onMounted(async () => {
  await router.isReady();
  if(!route.matched.some(({ path }) => path.startsWith('/auth'))){
    try {

      await getCategories()
      await getAttributes()
    } catch (err) {
      console.log(err)
    }
  }
})
</script>
<template>
  <RouterView />
</template>

<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
