import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'
import { api } from '@/api/api'
import { Attribute, Option, Category } from '../types/product'

export const useProductStore = defineStore('store', () => {
  //const activeProductId = ref(null)
  const attributes: Ref<Attribute[]> = ref([]),
    options: Ref<Option[]> = ref([]),
    productAttributes: Ref<string[]> = ref([]),
    productOptions: Ref<Option[]> = ref([]),
    categories: Ref<Category[]> = ref([])

  async function getCategories() {
    const { data } = await api.service.getCategories()
    categories.value = data
  }

  async function addCategory(data: Category) {
    await api.service.addCategory(data)
    await getCategories()
  }

  function getCategoryName(id: number) {
    const index = categories.value.findIndex((c) => c.id === id)
    return index >= 0 ? categories.value[index].name : ''
  }

  async function addAttribute(name: string): Promise<Option> {
    const { data } = await api.service.addAttribute({ name })
    await getAttributes()
    return data
  }

  async function addOption(name: string, attributeId: number): Promise<Option> {
    const { data } = await api.service.addOption({ name, attributeId })
    const option: Option = {
      id: data.id,
      name,
      attributeId,
    }
    return option
  }

  function removeAttributeFromProduct(id: string): void {
    const index = productAttributes.value.indexOf(id)
    productAttributes.value.splice(index, 1)
  }

  function getAttributeOptions(attributeId: string): Option[] {
    const index = attributes.value.findIndex((attribute) => attribute.id === attributeId)
    return attributes.value[index].options
  }

  function toggleOptionActivity(id: string): void {
    if (productOptions.value.includes(id)) {
      const index = productOptions.value.indexOf(id)

      productOptions.value.splice(index, 1)
    } else {
      productOptions.value.push(id)
    }
  }

  function getAttributeNameById(id: string): string {
    const index: number = attributes.value.findIndex((attribute) => attribute.id === id)
    return attributes.value[index].name
  }

  function getOptionNameById(id: string): string {
    const index = options.value.findIndex((option) => option.id === id)
    return options.value[index].name
  }

  function getProductAttributeOptions(id: string): string[] {
    return options.value
      .filter((option) => option.attributeId === id && productOptions.value.includes(option.id))
      .map((option) => option.id)
  }

  function getOptionById(id: string) {
    const index = options.value.findIndex((option) => option.id === id)
    return options.value[index]
  }

  async function getAttributes() {
    const { data } = await api.service.getAttributes()
    attributes.value = data
  }

  return {
    attributes,
    options,
    productAttributes,
    productOptions,
    categories,
    addAttribute,
    removeAttributeFromProduct,
    addOption,
    getAttributeOptions,
    toggleOptionActivity,
    getAttributeNameById,
    getOptionNameById,
    getProductAttributeOptions,
    getOptionById,
    getCategories,
    addCategory,
    getCategoryName,
    getAttributes,
  }
})
