import axios from 'axios'
function appendSlash(request) {
  if (!request.url.endsWith('/')) {
    request.url += '/'
  }
}

const apiV2Root = axios.create({
  baseURL: import.meta.env.VITE_API_ROOT,
  transformRequest: [
    function (data, headers) {
      alert()
      return data
    },
    ...axios.defaults.transformRequest,
  ],
})

apiV2Root.interceptors.request.use((request) => {
  appendSlash(request)

  return request
}, null)

const apiV2AuthRoot = axios.create({
  baseURL: import.meta.env.VITE_API_ROOT,
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      const accessToken = localStorage.getItem('token')
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`
      }

      return data
    },
    ...axios.defaults.transformRequest,
  ],
})


apiV2AuthRoot.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    window.location = `/auth/login`
  }
  return Promise.reject(error)
})

export { apiV2Root, apiV2AuthRoot }
